import React, { useState, useEffect } from "react";
import vrPlaceholderImage from "../assets/images/promo/vr-headset-placeholder-v2.png";

const MarsPromoSection = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768); // Responsive state
  const [isHovered, setIsHovered] = useState(false); // Hover state for button
  const [isHoveredVR, setIsHoveredVR] = useState(false);
  const [showVR, setShowVR] = useState(false);

  // Add a listener to handle window resize and update the state
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const containerStyle = {
    display: "flex",
    flexDirection: isMobile ? "column" : "row", // Stack for mobile, side-by-side otherwise
    justifyContent: "center", // Reduce the spacing between the text and image
    alignItems: "center", // Center content vertically
    padding: "20px",
    boxSizing: "border-box", // Ensure padding doesn't affect width
    gap: isMobile ? "20px" : "5%", // Adjust spacing between text and image
    border: "1px solid #ddd",
    borderRadius: "8px",
    overflow: "hidden",
    cursor: "pointer",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    transition: "transform 0.3s ease, box-shadow 0.3s ease",
    margin: "20px 20px 0px 20px",
    position: "relative",
    backgroundColor: "rgba(214, 205, 166, 0.1)",
  };

  const textContainerStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start", // Left-align the text
    textAlign: "left", // Ensure text alignment is left
    marginLeft: isMobile ? "0" : "5%", // Reduced spacing for non-mobile
    marginRight: isMobile ? "0" : "5%", // Reduced spacing for non-mobile
    padding: isMobile ? "10px" : "0", // Optional padding for mobile
    maxWidth: "40%", // Limit text width for better layout
  };

  const headerStyle = {
    fontSize: "5rem",
    fontWeight: "bold",
    marginBottom: "10px",
  };

  const subheaderStyle = {
    fontSize: "1.25rem",
    color: "#555", // Subtle color for subheader
    marginBottom: "20px",
  };

  const subSubheaderStyle = {
    fontSize: "1rem",
    color: "#555", // Subtle color for subheader
    marginBottom: "20px",
  };

  const imageStyle = {
    maxWidth: "100%", // Ensure the image does not exceed the width of the container
    maxHeight: "100vh", // Prevent the image from exceeding the viewport height
    objectFit: "contain", // Maintain aspect ratio and fit within the container
  };

  const buttonContainerStyle = {
    display: "flex",
    gap: "15px", // Space between buttons
    marginTop: "20px",
  };

  const buttonMobileContainerStyle = {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  };

  const buttonBaseStyle = {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px 20px",
    fontSize: "16px",
    fontWeight: "bold",
    color: "#333",
    backgroundColor: "#fff",
    border: `2px solid #d6cda6`,
    borderRadius: "8px",
    cursor: "pointer",
    textDecoration: "none",
    transition: "background-color 0.3s ease, color 0.3s ease",
  };

  const buttonVrStyle = {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: "20px",
    fontSize: "16px",
    fontWeight: "bold",
    color: "#333",
    backgroundColor: "#fff",
    border: `2px solid #d6cda6`,
    borderRadius: "8px",
    cursor: "pointer",
    textDecoration: "none",
    transition: "background-color 0.3s ease, color 0.3s ease",
  };

  const buttonHoverStyle = {
    backgroundColor: "#d6cda6",
    color: "#fff",
  };

  const iconStyle = {
    marginLeft: "10px", // Space between text and icon
  };

  const vrBox = {
    width: "100%",
    height: isMobile ? "50vh" : "70vh",
    backgroundImage: `url(${vrPlaceholderImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    borderRadius: "8px",
    boxShadow: "none", // No shadow for this element
    marginTop: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  };
  const vrButton = {
    backgroundColor: "#d6cda6",
    color: "black",
    padding: "10px 20px",
    fontSize: "16px",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  };
  const fullscreenContainer = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    backgroundColor: "#000",
    zIndex: 9999,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };
  const iframe = {
    width: "100%",
    height: "100%",
    border: "none",
  };
  const closeButton = {
    position: "absolute",
    bottom: "-10px",
    right: "-50px",
    transform: "translate(-50%, -50%)",
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    color: "black",
    padding: "10px 20px",
    fontSize: "16px",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    fontWeight: "bold",
    zIndex: 10000,
  };

  return (
    <div style={containerStyle}>
      {/* Left Side: Header and Subheader */}
      {!isMobile && (
        <div style={textContainerStyle}>
          <h1 style={headerStyle}>MARS</h1>
          <h2 style={subheaderStyle}>Sarajevo, Otoka, Radenka Abazovića</h2>
          {/* <h4 style={subSubheaderStyle}>Trenutno u izgradnji. Prodaja stanova počinje u 2025. godini.</h4> */}

          {/* Buttons */}
          <div style={buttonContainerStyle}>
            <a
              href="/project/10"
              style={{
                ...buttonBaseStyle,
                ...(isHovered ? buttonHoverStyle : {}),
              }}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              Pogledaj Stanove
              {/* <FaArrowRight style={iconStyle} /> */}
            </a>
            <a
              style={{
                ...buttonBaseStyle,
                ...(isHoveredVR ? buttonHoverStyle : {}),
              }}
              onMouseEnter={() => setIsHoveredVR(true)}
              onMouseLeave={() => setIsHoveredVR(false)}
              onClick={() => setShowVR(true)}
            >
              Virtual Reality Tour
              {/* <FaArrowRight style={iconStyle} /> */}
              {/* <img
              src={vrPlaceholderImage}
              alt="vrplaceholdermars"
              style={{
                width: "50px",
                height: "50px",
                border: `1px solid transparent`,
                borderTopRightRadius: "8px",
                borderBottomRightRadius: "8px",
                marginLeft: "20px"
              }}
            /> */}
            </a>
          </div>
        </div>
      )}

      {isMobile && (
        <div
          style={{
            fontSize: "2.5rem",
            fontWeight: "bold",
          }}
        >
          MARS
        </div>
      )}

      {/* Right Side: Image */}
      <img
        src="https://ita-architecture-images.s3.eu-west-2.amazonaws.com/mars/mars-otoka-hero-without-bg.png"
        alt="MarsPromoImg"
        style={imageStyle}
      />

      {isMobile && (
        <div
          style={{
            fontSize: "1.25rem",
            color: "#555",
            textAlign: "center",
          }}
        >
          Sarajevo, Otoka, Radenka Abazovića
        </div>
      )}

      {isMobile && (
        <div style={buttonMobileContainerStyle}>
          <a
            href="/project/10"
            style={{
              ...buttonBaseStyle,
              ...(isHovered ? buttonHoverStyle : {}),
            }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            Pogledaj Stanove
            {/* <FaArrowRight style={iconStyle} /> */}
          </a>
          <a
            style={{
              ...buttonBaseStyle,
              ...(isHoveredVR ? buttonHoverStyle : {}),
            }}
            onMouseEnter={() => setIsHoveredVR(true)}
            onMouseLeave={() => setIsHoveredVR(false)}
            onClick={() => setShowVR(true)}
          >
            Virtual Reality Tour
            {/* <FaArrowRight style={iconStyle} /> */}
            {/* <img
          src={vrPlaceholderImage}
          alt="vrplaceholdermars"
          style={{
            width: "50px",
            height: "50px",
            border: `1px solid transparent`,
            borderTopRightRadius: "8px",
            borderBottomRightRadius: "8px",
            marginLeft: "20px"
          }}
        /> */}
          </a>
        </div>
      )}

      {showVR && (
        <div
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          <div style={fullscreenContainer}>
            <iframe
              src={"https://mars.ita-architecture.com/"}
              title={"Mars"}
              style={iframe}
            ></iframe>
            <button style={closeButton} onClick={() => setShowVR(false)}>
              Zatvori VR
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default MarsPromoSection;

import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import projects from "../assets/projects/projects";

import { FaAngleLeft } from "react-icons/fa";
import { FaTimes } from "react-icons/fa";

import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

import vrPlaceholderImage from "../assets/images/vr-placeholder.jpg";
import overlayImage from "../assets/images/hero-sketch.png";

function ApartmentList({ apartments }) {
  const [selectedApartment, setSelectedApartment] = useState(null);

  const handleCardClick = (id) => {
    setSelectedApartment((prev) => (prev === id ? null : id));
  };

  return (
    <div style={{ marginBottom: "0px" }}>
      <h3 style={{ textAlign: "center", margin: "40px 20px 20px 20px" }}>
        Tlocrti Stanova
      </h3>
      <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
        {apartments.map((apartment) => (
          <div>
            <div
              key={apartment.id}
              // onClick={() => handleCardClick(apartment.id)}
              style={{
                padding: "10px 15px",
                border: "1px solid #ddd",
                borderRadius: "8px",
                overflow: "hidden",
                cursor: "pointer",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                transition: "transform 0.3s ease, box-shadow 0.3s ease",
                textAlign: "center",
                background:
                  "linear-gradient(135deg, #f6f4eb, #f6f4eb, #f6f4eb)",
              }}
            >
              <span style={{ fontSize: "16px", fontWeight: "bold" }}>
                {apartment.name}
              </span>
            </div>
            <div
              key={apartment.id}
              style={{
                position: "relative",
                textAlign: "center",
              }}
            >
              {/* <FaTimes
                // onClick={() => setSelectedApartment(null)}
                style={{
                  position: "absolute",
                  top: "30px",
                  left: "10px",
                  cursor: "pointer",
                  color: "#333",
                  fontSize: "20px",
                  backgroundColor: "white",
                  borderRadius: "50%",
                  padding: "5px",
                }}
              /> */}
              <img
                src={apartment.floorplan}
                alt={apartment.name}
                style={{
                  width: "100%",
                  maxWidth: "600px",
                  height: "auto",
                  borderRadius: "5px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                  margin: "20px auto",
                }}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

function ProjectDetail() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [project, setProject] = useState(null);
  const [showIframe, setShowIframe] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  // Fetch the project object
  useEffect(() => {
    const fetchedProject = projects.find((proj) => proj.id === Number(id));

    if (fetchedProject) {
      setProject(fetchedProject);
    } else {
      console.error(`Project with id ${id} not found.`);
    }
  }, [id]);

  // Handle responsiveness
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [id]);

  if (!project) {
    return <p>Project not found</p>;
  }

  const galleryImages = project.images.map((image) => ({
    original: image,
    thumbnail: image,
  }));

  const styles = {
    pageContainer: {
      // padding: "20px",
      maxWidth: "1200px",
      margin: "0 auto",
      backgroundColor: "white",
    },
    componentContainer: {
      padding: "0px 20px 0px 20px",
    },
    backButton: {
      display: "flex",
      alignItems: "center",
      position: "absolute",
      top: "68px",
      left: "20px",
      padding: "10px 15px",
      fontSize: "14px",
      color: "black",
      backgroundColor: "#f6f4eb",
      border: "none",
      borderRadius: "5px",
      cursor: "pointer",
      zIndex: 10,
    },
    hero: (isMobile) => ({
      display: "flex",
      justifyContent: isMobile ? "center" : "space-between",
      flexDirection: isMobile ? "column" : "row",
      alignItems: isMobile ? "center" : "flex-start",
      gap: "20px",
      width: "100%",
    }),
    cardContainer: (isMobile, isGallery) => ({
      width: "100%",
      maxWidth: "100%",
      flex: "1 1 auto",
      overflow: "hidden",
    }),
    card: {
      backgroundColor: "white", // Pure white background
      // border: "1px solid rgba(200, 200, 200, 1)", // Clean border
      borderRadius: "8px", // Smooth rounded corners
      boxShadow: "none", // Remove the shadow entirely for clarity
      overflow: "hidden",
      width: "100%",
      marginTop: "35px",
      marginBottom: "10px",
    },
    galleryContainer: {
      width: "100%",
      height: "auto", // Ensure it adapts to content
      display: "flex",
      justifyContent: "center", // Center the gallery horizontally
      alignItems: "center", // Center the gallery vertically (if needed)
    },
    cardHeaderImage: {
      width: "100%",
      borderTopLeftRadius: "8px",
      borderTopRightRadius: "8px",
    },
    cardBody: {
      marginTop: "10px",
      position: "relative",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      color: "black",
      flexDirection: "column",
      // padding: "15px",
    },
    cardTitle: {
      fontSize: "20px",
      fontWeight: "bold",
      marginTop: "20px",
      color: "black",
      background: "linear-gradient(135deg, #f6f4eb, #f6f4eb, #f6f4eb)", // Brushstroke-like gradient
      backgroundPosition: "0 0", // Position it at the start
      width: "100%",
      padding: "15px",
      textAlign: "center", // Center the text (optional)
      borderRadius: "8px",
    },
    cardDescription: {
      fontSize: "16px",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      marginTop: "20px",
    },
    vrBox: {
      width: "100%",
      height: isMobile ? "50vh" : "70vh",
      backgroundImage: `url(${vrPlaceholderImage})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      borderRadius: "8px",
      boxShadow: "none", // No shadow for this element
      marginTop: "0px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
    },
    vrButton: {
      background: "linear-gradient(135deg, #f6f4eb, #f6f4eb, #f6f4eb)",
      color: "black",
      padding: "10px 20px",
      fontSize: "16px",
      border: "none",
      borderRadius: "5px",
      cursor: "pointer",
    },
    fullscreenContainer: {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100vw",
      height: "100vh",
      backgroundColor: "#000",
      zIndex: 9999,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    iframe: {
      width: "100%",
      height: "100%",
      border: "none",
    },
    closeButton: {
      position: "absolute",
      bottom: "10px",
      right: "10px",
      backgroundColor: "rgba(255, 255, 255, 0.8)",
      color: "black",
      padding: "10px 20px",
      fontSize: "16px",
      border: "none",
      borderRadius: "5px",
      cursor: "pointer",
      fontWeight: "bold",
      zIndex: 10000,
    },
    overlayImageStyle: {
      zIndex: -10000,
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundImage: `url(${overlayImage})`,
      backgroundSize: "contain", // Adjust to fit the image proportionally
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      pointerEvents: "none", // Ensures it doesn't block text interactions
      // animation: "slideInFromBottom 1.5s ease-in-out", // Updated animation
      opacity: 0.5, // Adjust opacity (0 = fully transparent, 1 = fully opaque)
    },
  };

  return (
    <div style={styles.pageContainer}>
      {/* Back Button */}
      <button onClick={() => navigate(-1)} style={styles.backButton}>
        <FaAngleLeft />
        Nazad
      </button>

      <div style={styles.componentContainer}>
        <div style={styles.hero(isMobile)}>
          {/* Gallery */}
          <div style={styles.card}>
            {" "}
            <div>
              <div style={styles.cardBody}>
                <div style={styles.cardTitle}>{project.name}</div>
              </div>
            </div>
          </div>{" "}
        </div>
      </div>

      <ImageGallery
        items={galleryImages}
        showThumbnails={!isMobile}
        autoPlay={false}
        showFullscreenButton={false}
      />

      <div style={styles.componentContainer}>
        {project.hasVR && (
          <div
            style={{ display: "flex", flexDirection: "column", width: "100%" }}
          >
            <h3 style={{ textAlign: "center", margin: "40px 20px 20px 20px"  }}>Virtual Reality Tour</h3>
            {!showIframe ? (
              <div style={styles.vrBox} onClick={() => setShowIframe(true)}>
                <button style={styles.vrButton}>Započni VR</button>
              </div>
            ) : (
              <div style={styles.fullscreenContainer}>
                <iframe
                  src={project.vrLink}
                  title={project.name}
                  style={styles.iframe}
                ></iframe>
                <button
                  style={styles.closeButton}
                  onClick={() => setShowIframe(false)}
                >
                  Zatvori VR
                </button>
              </div>
            )}
          </div>
        )}
        {project.apartments && (
          <ApartmentList apartments={project.apartments} />
        )}
      </div>
    </div>
  );
}

export default ProjectDetail;

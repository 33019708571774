import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
// import Hero from './components/Hero';
// import Hero from "./components/Hero_v2";
import Hero from "./components/Hero_v3";
import ProjectList from "./components/ProjectList";
import ProjectDetail from "./components/ProjectDetail";
import Footer from "./components/Footer";
import AllProjects from "./components/AllProjects";
import Services from "./components/Services";
import FullScreenVideo from "./components/FullScreenVideo";
import MarsPromoSection from "./components/MarsPromo";

function App() {
  const appStyle = {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh", // Full height of the viewport
    fontFamily: "Arial, sans-serif",
  };

  const contentStyle = {
    flex: "1", // Ensures the content area takes up available space, pushing the footer down
    display: "flex",
    flexDirection: "column",
  };

  return (
    <Router>
      <div style={appStyle}>
        <Navbar />
        <div style={contentStyle}>
          <Routes>
            {/* Home Page */}
            <Route
              path="/"
              element={
                <>
                  <Hero />
                  <Services />
                  <MarsPromoSection />
                  <ProjectList />
                  {/* <FullScreenVideo /> */}
                  {/* <VRSection /> */}
                </>
              }
            />
            {/* All Projects Page */}
            <Route path="/projects" element={<AllProjects />} />
            {/* Project Detail Page */}
            <Route path="/project/:id" element={<ProjectDetail />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
